<template lang="">
    <div>
        <b-card no-body>
            <b-overlay
                :show="show"
                rounded="sm"
            >
                <b-card-header>
                    <h3>Driver Payroll Report</h3>
                 
                    <b-col class="text-right">
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            :to="{
                                name: 'apps-payroll-edit',
                                params: { id: 0 },
                            }"
                            style="left: -13px;position: relative;"
                        >
                            Fixed rate setting
                        </b-button>
                        <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            :to="{
                                name: 'apps-payroll-edit',
                                params: { id: 1 },
                            }"
                            style="left: -7px;position: relative;"
                        >
                            Spot pay setting
                        </b-button>
                        <b-button 
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="sendReports"
                            >Send Reports
                        </b-button>
                    </b-col>
                </b-card-header>
                <b-card-body>
                    <b-row class="d-flex align-items-center mb-1">
                        <b-col md="5" sm="4" xs="3">
                            <b-form-group>
                                <b-form-input
                                    v-model="searchTerm"
                                    placeholder="Search"
                                    type="text"
                                    class="d-inline-block"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center mb-1">
                        <b-col md="5">
                            <b-form-group label="From">
                                <b-form-datepicker
                                    id="example-datepicker1"
                                    v-model="fromDate"
                                    class="mb-1"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="5">
                            <b-form-group label="To">
                                <b-form-datepicker
                                    id="example-datepicker2"
                                    v-model="toDate"
                                    class="mb-1"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="4" xs="5">
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="search"
                            >
                                Search
                            </b-button>
                        </b-col>
                        
                    </b-row>
                    
                    <vue-good-table
                        striped
                        hover
                        responsive
                        :columns="columns"
                        :rows="rows"
                        @on-selected-rows-change="selectionChanged"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :search-options="{
                            enabled: true,
                            externalQuery: searchTerm }"
                        :select-options="{
                            enabled: true,
                            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                            selectionInfoClass: 'custom-class',
                            selectionText: 'rows selected',
                            clearSelectionText: 'clear',
                            disableSelectInfo: true, // disable the select info panel on top
                            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                        }"
                        >
                        <template
                            slot="table-row"
                            slot-scope="props"
                        >
                            <span v-if="props.column.field === 'action'">
                                <b-button
                                    @click="viewReport(props)"
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    variant="primary"
                                >
                                    <feather-icon icon="FileTextIcon" size="16" />
                                    View Report
                                </b-button>
                            </span>
                        </template>

                        <!-- pagination -->
                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                    Showing 1 to
                                    </span>
                                    <b-form-select
                                    v-model="pageLength"
                                    :options="pageOptions"
                                    class="mx-1"
                                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                                    />
                                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                    
                    <!-- <b-table
                        striped
                        hover
                        responsive
                        class="position-relative payroll-table"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="dataSource"
                        :fields="fields"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                    >
                        <template #cell(checkbox)="data">
                            <b-form-checkbox></b-form-checkbox>
                        </template>

                        <template #cell(action)="data">
                            <b-button
                                @click="viewReport(data)"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="primary"
                            >
                                <feather-icon icon="FileTextIcon" size="16" />
                                View Report
                            </b-button>
                        </template>
                    </b-table> -->

                    <!-- <b-card-body
                        class="d-flex justify-content-between flex-wrap pt-0"
                    > -->
                        <!-- page length -->
                        <!-- <b-form-group
                            label="Per Page"
                            label-cols="6"
                            label-align="left"
                            label-size="sm"
                            label-for="sortBySelect"
                            class="text-nowrap mb-md-0 mr-1"
                        >
                            <b-form-select
                                id="perPageSelect"
                                v-model="perPage"
                                size="sm"
                                inline
                                :options="pageOptions"
                            />
                        </b-form-group> -->

                        <!-- pagination -->
                        <!-- <div>
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                first-number
                                last-number
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mb-0"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div> -->
                    </b-card-body>
                </b-card-body>
            </b-overlay>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BButton,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BOverlay
} from "bootstrap-vue";
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import axios from "axios";
import Ripple from "vue-ripple-directive";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";

export default {
    components: {
        VueGoodTable,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BTable,
        BButton,
        BPagination,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormCheckbox,
        BFormDatepicker,
        BOverlay,
        flatPickr,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            rows: [],
            totalRows: 1,
            sortDesc: false,
            sortDirection: "desc",
            filter: " ",
            filterOn: [],
            searchTerm: '',
            columns: [
                {
                    field: "driver_id",
                    label: "DRIVER ID",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    field: "driver_name",
                    label: "NAME",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    field: "email",
                    label: "EMAIL",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    field: "action",
                    label: "ACTION",
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ],
            driver_name: null,
            fromDate: null,
            toDate: null,
            paymentDate: null,
            modes: ["multi", "single", "range"],
            selectedRows: [],
            show: false
        };
    },


    created() {
        const company = getUserData().company_id;

        axios
            .get(`${serverUri}/api/payroll/earningIndex/${company}`)
            .then((res) => {
                this.toDate = res.data.toDate;
                this.fromDate = res.data.fromDate;
                this.paymentDate = res.data.paymentDate
                if (res.data.res.length)
                    this.rows = res.data.res;

                    console.log(this.rows);

                    const SpotDataList = res.data.res_spots;

                    for(var i=0; i<SpotDataList.length;i++)
                    {
                        var exists = this.rows.some(function(field) {
                            return field.driver_id === SpotDataList[i].driver_id;
                        });

                        var k  = 0 ;
                        if(!exists)
                        {   
                            this.rows[parseInt(this.rows.length)+parseInt(k)] = SpotDataList[i];
                            k++;
                        }
                    }
            });
    },

    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        search() {
            axios
                .post(
                    `${serverUri}/api/payroll/searchdriver`, 
                    {
                        company: getUserData().company_id,
                        fromDate: this.fromDate,
                        toDate: this.toDate
                    }
                )
                .then((res) => {
                    if(res.data.length)
                        this.rows = res.data.map((p, i) => {
                            return {
                                ...p,
                                no: i + 1,
                            };
                        });
                });
        },
        viewReport(data) {
            if (this.fromDate && this.toDate) {
                this.$router.push({
                    name: "apps-payroll-report",
                    params: {
                        id: data.row.driver_id,
                        fromDate: this.fromDate,
                        toDate: this.toDate,
                        paymentDate: this.paymentDate
                    },
                });
            } else {
                this.showToast(
                    "warning",
                    "warning",
                    "Please select item."
                );
            }
        },
        showToast(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: "BellIcon",
                    text: text,
                    variant,
                },
            });
        },
        // onRowSelected(items) {
        //     this.selected = items;
        // },
        // selectAllRows() {
        //     this.$refs.selectableTable.selectAllRows();
        // },
        selectionChanged(params) {
            this.selectedRows = params.selectedRows
        },

        sendReports() {
            if (this.selectedRows.length) {
                this.show = !this.show;

                const getEmailItems = this.selectedRows.filter((item) => {
                    return item.email && item.email.includes('@');
                });

                const getNonEmailItems = this.selectedRows.filter((item) => {
                    return item.email == '' || item.email == null || item.email == 'null';
                });

                if (getNonEmailItems.length) {
                    getNonEmailItems.map((item) => {
                        this.showToast("warning", "warning", item.driver_id + " has no domicile email.");
                    })
                }

                axios
                    .post(
                        `${serverUri}/api/payroll/sendreports`, 
                        {
                            company: getUserData().company_id,
                            selectedRows: getEmailItems,
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                            paymentDate: this.paymentDate
                        }
                    )
                    .then((res) => {
                        this.show = !this.show;
                        if (res.data.status) 
                            this.showToast(
                                "success",
                                "Success",
                                "Items are sent successfully!"
                            );
                        else 
                            this.showToast(
                                "warning",
                                "No matched datas.",
                                "Please check the Items and Date ranges."
                            );
                    });
            } else {
                this.showToast(
                    "warning",
                    "warning",
                    "Please select item."
                );
            }
        }
    },
    computed: {
        direction() {
            if ($store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style>
.payroll-table th,
td {
    white-space: nowrap;
}

.vgt-table {
    width: 100%;
    text-align: center;
}

.vgt-table.vgt-table tbody tr:hover {
    cursor: pointer;
}

.vgt-table th, td {    
    padding: 0.72rem 2rem;
}

.vgt-table tr {
    border-top: 1px solid #f2f4f7;
    background-color: #fafafc;
}
[dir] .vgt-table.bordered td, [dir] .vgt-table.bordered th {
    border: 0px solid #dcdfe6;
    font-size: 0.857rem;
}
.vgt-table tr:nth-child(odd) {background-color: #f6f6f9;}
</style>
