var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('b-card-header',[_c('h3',[_vm._v("Driver Payroll Report")]),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticStyle:{"left":"-13px","position":"relative"},attrs:{"variant":"outline-primary","to":{
                            name: 'apps-payroll-edit',
                            params: { id: 0 },
                        }}},[_vm._v(" Fixed rate setting ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticStyle:{"left":"-7px","position":"relative"},attrs:{"variant":"outline-primary","to":{
                            name: 'apps-payroll-edit',
                            params: { id: 1 },
                        }}},[_vm._v(" Spot pay setting ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.sendReports}},[_vm._v("Send Reports ")])],1)],1),_c('b-card-body',[_c('b-row',{staticClass:"d-flex align-items-center mb-1"},[_c('b-col',{attrs:{"md":"5","sm":"4","xs":"3"}},[_c('b-form-group',[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)],1)],1),_c('b-row',{staticClass:"d-flex align-items-center mb-1"},[_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":"From"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker1"},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":"To"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"example-datepicker2"},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1),_c('b-col',{attrs:{"md":"2","sm":"4","xs":"5"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.search}},[_vm._v(" Search ")])],1)],1),_c('vue-good-table',{attrs:{"striped":"","hover":"","responsive":"","columns":_vm.columns,"rows":_vm.rows,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"search-options":{
                        enabled: true,
                        externalQuery: _vm.searchTerm },"select-options":{
                        enabled: true,
                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.viewReport(props)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"16"}}),_vm._v(" View Report ")],1)],1):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":_vm.pageOptions},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1)])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }